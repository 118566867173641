<template>
  <div class="fluid" id="assetMovementList">
    <div>
      <div class="asset-movement-list-div">
        <v-card class="px-5 py-3">
          <v-row>
            <v-col
              cols="12"
              class="d-flex"
              style="position:relative;padding:0 20px;text-align:center;margin-top:20px;"
            >
              <h3 style="font-weight:bold;margin:auto;">
                ASSET MANAGEMENT SYSTEM
              </h3>
            </v-col>
            <v-col cols="12">
              <div>
                <v-toolbar
                  class="asset-movement-list-toolbar-1"
                  flat
                  style="height: 60px; border-radius: 10px 10px 0 0"
                >
                  <v-toolbar-title>List Penerimaan Aset</v-toolbar-title>
                  <v-divider class="mx-6" inset vertical></v-divider>

                  <div style="width:200px;margin-right:10px;margin-left:90px;">
                    <v-text-field
                      class="ml-1 mr-2"
                      dense
                      label="Tanggal"
                      type="date"
                      name="startdate"
                      style="height:30px;"
                      step="1"
                    />
                  </div>
                  <div style="width:200px;margin-right:10px;">
                    <v-select
                      :items="dropDownCompany"
                      :value="2"
                      style=" height:30px;
                        position: relative;
                        top:3px;
                        margin-left: 10px;
                        font-size: 16px;
                      "
                      label="kepemilikan"
                      item-text="name"
                      item-value="id"
                      return-id
                      dense
                    ></v-select>
                  </div>
                  <div style="width:200px;margin-right:10px;">
                    <v-select
                      :value="2"
                      style=" height:30px;
                        position: relative;
                        top:3px;
                        margin-left: 10px;
                        font-size: 16px;
                      "
                      label="destinasi"
                      item-text="name"
                      item-value="id"
                      return-id
                      dense
                    ></v-select>
                  </div>
                  <div style="width:200px;margin-right:10px;">
                    <v-select
                      :value="0"
                      style=" height:30px;
                        position: relative;
                        top:3px;
                        margin-left: 10px;
                        font-size: 16px;
                      "
                      label="status"
                      item-text="name"
                      item-value="id"
                      return-id
                      dense
                    ></v-select>
                  </div>

                  <v-btn
                    elevation="2"
                    tile
                    large
                    color="black"
                    class="white--text"
                    style="width:130px; position:relative; left:15px;"
                    >search</v-btn
                  >
                </v-toolbar>
                <v-toolbar
                  class="asset-movement-list-toolbar-1"
                  flat
                  style="height: 60px; border-radius: 10px 10px 0 0;"
                >
                  <div>
                    <div style="width: 130px">
                      <v-select
                        label="Action"
                        style="
                      position: relative;
                      top: 15px;
                      margin-left: 5px;
                      font-size: 12px;
                    "
                        :items="[
                          { id: 0, name: '' },
                          { id: 1, name: 'Edit' },
                          { id: 4, name: 'Delete' },
                          { id: 5, name: 'Hide' }
                        ]"
                        item-text="name"
                        item-value="id"
                        outlined
                        return-id
                        dense
                      ></v-select>
                    </div>
                  </div>
                  <v-divider
                    class="mx-6"
                    inset
                    vertical
                    style="border:1px solid transparent;"
                  ></v-divider>
                  <div class="asset-movement-list-toolbar-div">
                    <v-text-field
                      v-model="paramAPI.keyword"
                      label="search here"
                      type="search"
                      append-icon="mdi-magnify"
                      outlined
                      dense
                      @keyup.enter="searchEnter"
                      style="position: relative; top: 15px; width: 10px"
                    ></v-text-field>
                  </div>
                </v-toolbar>
              </div>
              <v-toolbar
                class="attendance-list-toolbar-2"
                flat
                style="
                  height: 60px;
                  border-radius: 10px 10px 0 0;
                  border-bottom: 1px solid #e0e0e0;
                "
              >
                <v-text-field
                  v-model="paramAPI.keyword"
                  label="Search here"
                  type="search"
                  outlined
                  dense
                  @keyup.enter="searchEnter"
                  style="position: relative; top: 15px; width: 10px"
                ></v-text-field>
              </v-toolbar>
            </v-col>

            <v-col cols="12">
              <v-data-table
                fixed-header
                height="57vh"
                style="cursor: pointer;"
                :headers="headers"
                :items="result"
                :items-per-page="10"
                :loading="loading"
                @click:row="rowClick"
                item-key="id"
                :show-select="true"
                :single-select="true"
              >
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </div>
    <movement-detail />
  </div>
</template>
<script>
// import axios from 'axios'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import buildType from '../../../services/buildType'
export default {
  components: {
    MovementDetail: () => import('./components/Detail')
  },
  data: () => ({
    attendance: buildType.apiURL('attendance'),
    isAdmin: true,
    paramAPI: {
      keyword: ''
    },
    headers: [
      {
        text: 'No. Pemindahan',
        value: 'movement_no',
        align: 'left',
        sortable: false
      },
      {
        text: 'PIC',
        value: 'pic',
        align: 'left',
        sortable: false
      },
      {
        text: 'Plant',
        value: 'plant',
        align: 'left',
        sortable: false
      },
      {
        text: 'Tgl. Pemindahan',
        value: 'movement_date',
        align: 'left',
        sortable: false
      },
      {
        text: 'Destinasi',
        value: 'destination',
        align: 'left',
        sortable: false
      },
      {
        text: 'Total Item',
        value: 'total_item',
        align: 'left',
        sortable: false
      },
      {
        text: 'Diterima Oleh',
        value: 'received_by',
        align: 'left',
        sortable: false
      },
      {
        text: 'Department',
        value: 'department',
        align: 'left',
        sortable: false
      },
      {
        text: 'Tgl. Terima',
        value: 'received_date',
        align: 'left',
        sortable: false
      },
      {
        text: 'Status',
        value: 'status',
        align: 'left',
        sortable: false
      }
    ],
    result: [
      {
        pic: 'arif'
      }
    ],
    detail: null,
    loading: false,
    dropDownCompany: []
  }),
  created() {
    this.dropdownCompany()
    this.initDataTable()
  },
  computed: {
    ...mapGetters(['getIsLoading', 'getUserProfile'])
  },
  methods: {
    ...mapActions([]),
    ...mapMutations(['setIsLoading']),

    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },

    dropdownCompany() {
      this.dropDownCompany = [
        { id: 0, name: 'Company' },
        { id: 1, name: 'IMP' },
        { id: 2, name: 'TMP' },
        { id: 3, name: 'GIT' },
        { id: 4, name: 'KAJ' },
        { id: 5, name: 'WIM' },
        { id: 6, name: 'SMI' }
      ]
    },
    searchEnter() {
      this.initDataTable()
    },
    initDataTable() {},
    rowClick(pValue) {
      document.getElementById('movementDetail').click()
    }
  }
}
</script>

<style lang="scss">
#assetMovementList {
  margin-bottom: 10px;
  .asset-movement-list-div {
    padding: 40px 15px;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .asset-movement-list-toolbar-1 {
      .asset-movement-list-toolbar-div {
        width: 30%;
        display: flex;
        flex-direction: row;
        margin-left: auto;
      }
    }
    .attendance-list-toolbar-2 {
      display: none;
    }
  }
}

.arch-add-new-btn {
  padding: 1px 4px;
  box-shadow: 1px 1px 1px rgb(134, 132, 132);
  border: 1px solid #cacad4;
  cursor: pointer;
  margin-top: 5px;
  text-align: left;
  font-size: 12px;
  &:hover {
    box-shadow: 1px 2px 7px rgb(134, 132, 132);
    color: blue;
    text-decoration: underline;
  }
}

.arch-expDate {
  cursor: pointer;
  height: 40px;
  .arch-exp-date {
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;

    .arch-exp-date-notif {
      overflow: hidden;
      width: 120px;
      position: absolute;
      left: 0;
      .exp-date-notif-div {
        display: flex;

        .exp-date-notif-chip1 {
          width: 95px;
          font-size: 10px;
        }
        .exp-date-notif-chip2 {
          font-size: 10px;
          .date-notif-chip2-p {
            width: 70px;
            margin: 0;
            margin: auto;
            text-align: center;
            // padding: 0 7px;
          }
        }
      }
    }
  }

  &:hover .arch-exp-date-notif {
    width: 0;
    transition: 0.5s;
  }
}
@media (max-width: 768px) {
  #assetMovementList {
    .asset-movement-list-div {
      .asset-movement-list-toolbar-1 {
        .asset-movement-list-toolbar-div {
          display: none;
        }
      }
      .attendance-list-toolbar-2 {
        display: block;
      }
    }
  }
}
</style>
